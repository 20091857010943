<template>
  <div class="root">
    <van-cell-group title="">
      <div class="content">
        <div class="title">
          <span>{{ title }}</span>
        </div>
        <div v-html="content" />
      </div>
    </van-cell-group>
    <van-cell-group title="如同意以上声明及开户中已确认内容，请签名">
      <div class="image" @click="handleShowSignatrue">
        <van-image
          v-if="signatureData"
          width="250px"
          height="100px"
          fit="contain"
          :src="signatureData"
        />
        <div v-else style="height: 100px;line-height: 100px;">
          点击签名
        </div>
      </div>
    </van-cell-group>
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="next" :disabled="!signatureData">
        下一步（上传资料）
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>
    <div class="signatrueMask" v-show="showSignatrue">
      <div class="signatrue" @touchmove.stop.prevent>
        <canvas ref="signatrue" class="canvas" />
        <div class="btns">
          <van-button type="danger" class="btn" @click="handleClearSignatrue">
            清除
          </van-button>
          <van-button type="default" class="btn" @click="handleCancelSignatrue">
            取消
          </van-button>
          <van-button type="primary" class="btn" @click="handleOkSignatrue">
            确定
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, CellGroup, Image as VanImage } from "vant";
import { useStore } from "vuex";
import { onMounted, ref, computed } from "vue";
import SignaturePad from "signature_pad";
import httpApi from "../../utils/httpApi";
import httpAddApi from "../../utils/httpAddApi";

const title = "客户声明";
const content = `1. 本人欲使用香港蜂投证券有限公司(下称“蜂投证券”)所提供电子交易服务账户，本人已阅读及明白并同意接受可不时被修改的协议书包括其现在账户之附件条款。
2. 本人声明此开户申请表上填报的资料均属真实，完全及正确的。本人亦重申蜂投证券或其代理人，已获本人授权，可随时联络本人之银行、经纪及任何信贷咨询机构，以作评核本人信贷之用。
3. 本人声明及承认(1)本人已阅读蜂投证券客户协议书和期货客户交易协议及风险披露声明；(2)有关方面已向本人提供客户协议书和期货客户交易协议之条款，条件及相关之风险声明；(3)本人确认已收到一份条款及条件之副本，内含风险披露声明及有关个人资料(私隐)条例之通告及已获邀请阅读该风险披露声明，提出问题及征求独立意见(如本人有此意愿)；(4)本人完全明白所有条款及守则，并同意受其约束。
4. 本人明白常设授权包函客户证券和客户款项两项，且细阅及确认同意《客户常设授权》的内容。常设授权（证券）适用于证券保证金的账户，并不适用于只持有证券现金户囗的客户。常设授权（款项）同时适用于证券保证金的账户/证券现金账户/期货账户的账户(如有)。常设授权有效期为一年，蜂投证券会在该授权届满前不少于十四天前向本人发出书面通知，如到期日未收到本人书面反对，则表示本人同意及接受自动延长常设授权并继续受其约束。
5. 本人确认已经阅读并理解蜂投证券有关沪深港通证券风险披露及个人资料收集声明的内容。本人应在完全理解其性质及风险评估后才进行交易。本人交易前会细阅及同意客户协议和期货客户交易协议中的相关风险声明，并按本身的经验、目的、财务资源及其他因素小心考虑(有需要时资询顾问) 该等交易是否适合。本人会遵守中国及香港相关的法律和法规，和一切有关交易所的条例，包括但不限于上海证券交易所之上市条例、上海证券交易所条例、深圳证券交易所之上市条例、深圳证券交易所条例及其他有关法律及法规。本人确认已经阅读并理解蜂投证券有关沪深港通证券风险披露及同意蜂投证券将本人吾等的个人资料用于个人资料收集声明所载的目的。
6. 本人同意蜂投证券或连络人士以各种方式提供投资或有关财务产品及服务的资料，其包括研究报告及特别优惠等。
7. 本人确认蜂投证券可向介绍人支付转介费或介绍费，而该介绍人并非蜂投证券的代理人。
8. 本人知悉及同意，蜂投证券可根据《税务条例》（第 112 章）有关交换财务账户资料的法律条文，（a）收集本表格所载资料并可备存作自动交换财务账户资料用途及（b）把该等资料和关于账户持有人及任何须申报账户的资料向香港特别行政区政府税务局申报，从而把资料转交到账户持有人的居留司法管辖区的税务当局。本人证明，就有关本表格所述现于蜂投证券持有的所有账户，本人均是账户持有人 (或本人获账户持有人授权代其签署)。本人承诺，如情况有所改变，以致影响本表格所述的个人的税务居民身分，或引致本表格所载的资料不正确，本人会通知蜂投证券，并会在情况发生改变后 30 日内，向蜂投证券提交一份已适当更新的自我证明表格。

9. 本人确认、同意并授权贵公司，依据贵公司个人资料适用政策、通告及文件，在适当的情况下向第三方（包括但不限于本地或海外之法定、监管、政府、税务、执法或其他机构或金融服务提供者）披露本人的个人资料。本人确认，截至本同意书签发日止，本人于贵公司所保存的个人资料均属真实、正确、最新及完整的资料。如本人在贵公司所保存之资料有任何更改，本人承诺会实时以 贵公司规定之表格通知 贵公司。本人明白及确认，在适当的情况下，本人之个人资料或须披露予其他司法管辖区的主管当局，包括但不限于台湾及美国主管当局。

10.本人确认上述衍生产品认知评估资料真确无误。本人完全根据本人的判断及决定向蜂投证券提供以上资料。

11.本人确认衍生工具知识评估是为符合证券及期货事务监察委员会持牌人或注册人操守准则第 5.1A 节所载的投资者分类的监管要求，以让蜂投证券了解本人是否对衍生工具的性质及风险有基本知识。

12.本人确认已完全了解“衍生产品基本常识”内的内容所陈述有关衍生产品的性质、常见种类、用途以及相关之风险，并已获提供附于本文内的风险披露声明。本人已获邀阅读该风险披露声明、提出问题及征求独立的意见。

13.本人有意买卖衍生产品，并已阅读及完全明白“认识一般衍生产品”简报中关于“衍生产品所涉及的风险”部分的所有内容。本人愿意承担交易相关产品所带来的潜在风险。
附注：买卖衍生及结构性投资产品包括(但不限于)以下产品：房地产投资信托基金及交易所买卖基金/债券、股票挂钩票据、货币挂钩票据/投资合约、结构性票据、认股权证、期货合约及期权合约及交易所买卖基金以外的单位信托/互惠基金。

14. 我已同意以电子签名的方式在开立账户时或其他时间去签署其他额外文件，包括但不限于风险披露声明以及其他相关的文件。
15.本人确认及同意从本人在本表格上收集的资料及日后之任何改动或补充，代表蜂投证券有限公司所收集。本人进一步确认及确定，本人已收讫及阅毕与蜂投证券有限公司签定的客户协议中所载的个人资料收集声明（及修订版本）（客户协议）。本人确认及同意所收集的资料及日后之任何改动或补充，可根据个人资料收集声明内的使用及目的（包括直接促销）使用、转交受让人的描述，进行使用及转交。

16.本人可致函蜂投证券有限公司香港中环皇后大道中99号中环中心39楼5室，要求查阅及更改本人的个人资料。`.replace(
  /\r?\n/g,
  "<br />"
);

export default {
  name: "Home",
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [VanImage.name]: VanImage
  },
  data() {
    return {};
  },
  methods: {
    async next() {
      if (!this.signatureData) return;
      const params = { risk_tell_pic: this.signatureData };
      const httprequest = this.isAddAccount
        ? httpAddApi.updateRiskTellSign
        : httpApi.postRiskDisclosurStepTwo;
      const result = await httprequest(params);
      if (result) {
        if (this.isAddAccount) await this.$api.submitLog({ type: "submitAddRiskTellSign" });
        const path = this.isAddAccount ? "/upload/add" : "/upload/index";
        this.$router.push({ path });
      }
    },
    before() {
      this.$router.back();
    },

    // 显示签名
    handleShowSignatrue() {
      this.showSignatrue = true;
      this.$refs.signatrue.width = window.innerWidth - 44;
      this.$refs.signatrue.height = window.innerHeight - 46;
      // 获取到签名数据之后执行
      this.signaturePad = new SignaturePad(this.$refs.signatrue, {
        minWidth: 1,
        maxWidth: 5
      });
    },

    // 签名确认
    handleOkSignatrue() {
      const data = this.signaturePad && this.signaturePad.toDataURL();
      if (this.signaturePad.isEmpty()) {
        this.signatureData = "";
        this.showSignatrue = false;
      } else {
        this.rotateBase64Image(data);
      }
    },

    // 签名清除
    handleClearSignatrue() {
      return this.signaturePad.clear();
    },

    // 签名取消
    handleCancelSignatrue() {
      this.showSignatrue = false;
    },

    // 图片旋转
    rotateBase64Image(base64data) {
      const image = new Image();
      image.src = base64data;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.height = image.width;
        canvas.width = image.height;
        const ctx = canvas.getContext("2d");
        ctx.translate(Math.floor(image.height / 2), Math.floor(image.width / 2));
        ctx.rotate((-90 * Math.PI) / 180);
        ctx.drawImage(image, -image.width / 2, -image.height / 2);
        this.showSignatrue = false;
        this.signatureData = canvas.toDataURL();
      };
    }
  },
  setup() {
    const showSignatrue = ref(false);
    const signaturePad = null;

    const signatureData = ref("");
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    onMounted(async () => {
      store.commit("setTitleValue", "风险认知");
      if (isAddAccount.value) {
        store.commit("setStepsValue", ["风险认知", "提交资料(1/2)"]);
        store.commit("setStepsActive", 1);
      } else {
        store.commit("setStepsValue", ["填写资料", "风险认知", "提交资料(1/2)"]);
        store.commit("setStepsActive", 2);
      }
      const httprequest = isAddAccount.value
        ? httpAddApi.getRiskTellSign
        : httpApi.getRiskDisclosurStepTwo;
      const result = await httprequest();
      if (!result) return;
      signatureData.value = result.data.risk_tell_pic;
    });

    return {
      title,
      isAddAccount,
      content,
      signatureData,
      showSignatrue,
      signaturePad
    };
  }
};
</script>

<style lang="less">
.root {
  text-align: left;

  .content {
    margin-top: @space-2;
    padding: @space-2;
    background: @contentColor;
    font-size: @font-size-2;
  }

  .title {
    text-align: center;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: @space-2 0;
  }

  .signatrueMask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: flex;

    .signatrue {
      position: relative;
      flex: 1;
      background-color: white;

      .canvas {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 44px;
      }

      .btns {
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        display: flex;
        transform: rotate(90deg);
        transform-origin: left bottom;

        .btn {
          width: 50%;
          margin: 0 @space-1;
        }
      }
    }
  }

  .btn {
    width: 80%;
    margin: @space-0 auto;
    margin-bottom: 0;
  }
}
</style>
